<style lang="scss">
#debug {
  display: none;
}

#frontend {
  .management-all {
    .headline-box-std {
    }

    .bg-grey {
      background: #f4f4f4;

      #vorstaende-nav {
        //border:1px solid red;
        padding: 40px 0;
        display: flex;
        justify-content: center;

        @media (max-width: $width-lg) {
          padding: 20px 0;
        }

        li {
          //border:1px solid red;
          width: 33.33%;
          text-align: center;

          @media (max-width: $width-lg) {
            width: 100%;
          }

          .nav-link {
            border: 0;
            display: block;
            color: #000;
            font-family: 'Helvetica Neue LT W05_75 Bold';
            background: none;
            font-size: 160%;
            width: 100%;

            //border:1px solid green;
            @media (max-width: $width-lg) {
              width: 100%;
              text-align: center;

              &:hover {
                color: #000;
                opacity: 0.7;
              }
            }

            .name {
              letter-spacing: 1px;
              display: inline-block;
              //border:1px solid green;
            }

            .sub-title {
              display: block;
              font-family: 'Helvetica Neue LT W05 45 Light';
              font-size: 60%;
              letter-spacing: 1px;
              text-align: left;

              @media (max-width: $width-lg) {
                text-align: center;
              }
            }
          }

          .active {
            color: $coral;

            &:hover {
              color: $coral;
              opacity: 1;
            }
          }
        }
      }
    }

    .tab-content {
      margin-top: 100px;
      margin-bottom: 200px;

      .img-fluid {
        position: relative;
        z-index: 2;
      }

      .txt-box {
        position: relative;
        top: 100px;

        .line {
          width: 150%;
          position: relative;
          left: -50%;
          border-bottom: 1px solid #000;
        }

        .zitat-box {
          //border:1px solid red;
          position: relative;
          margin-bottom: 50px;
          margin-top: 50px;

          .zitat-icon {
            position: absolute;
            width: 55px;
            height: 55px;

            @media (max-width: $width-sm) {
              position: relative;
              margin: 0 auto;

              display: block;
            }

            //border:1px solid red;
          }

          .zitat-icon-top {
            left: -50px;
            top: 0;

            @media (max-width: $width-sm) {
              left: 0;
            }
          }
        }

        h2 {
          font-family: 'American Typewriter ITC W04 Md';
          text-align: center;
          line-height: 1.5;
          font-size: 150%;
          text-transform: none;
          padding: 10px 15px;

          @media (max-width: $width-lg) {
            font-size: 150%;
          }

          @media (max-width: $width-md) {
            font-size: 130%;
          }

          .breaker {
            display: block;
            height: 1px;

            @media (max-width: $width-lg) {
              display: none;
            }
          }
        }
      }
    }

    .border-boxen {
      //border:1px solid red;
      .col-12 {
        position: relative;

        //border:1px solid blue;
        .inner-box-border {
          border: 10px solid $blue-60per;

          width: 100%;
          position: relative;

          .txt-box {
            //border:1px solid green;
            padding: 60px 80px 60px 40px;
            max-width: 500px;

            @media (max-width: 500px) {
              padding: 30px;
              width: 100%;
            }

            h3 {
              text-transform: none;
              margin-bottom: 20px;
              font-family: 'Helvetica Neue LT W05_75 Bold';
            }

            p {
              margin-bottom: 0;
            }
          }

          .btn-box-collapse {
            border-top: 10px solid $blue-60per;
            padding: 20px 30px;

            .btn-inner {
              display: block;
              //border:1px solid green;
              font-family: 'Helvetica Neue LT W05 65 Medium';
              text-transform: uppercase;
              font-size: 80%;
              letter-spacing: $letter-spacing-std;

              &:hover {
                color: #000;
                opacity: 0.7;
              }
            }
          }
        }

        .inner-box-left {
          //transform: translateX(-670px);
          left: -670px;

          @media (max-width: 1300px) {
            left: -600px;
          }

          @media (max-width: $width-xl) {
            left: -200px;
          }

          @media (max-width: 800px) {
            left: -60px;
          }

          @media (max-width: $width-md) {
            left: 0px;
          }

          .txt-box {
            margin-left: auto;
          }

          .btn-box-collapse {
            text-align: left;

            .btn-inner {
              max-width: 430px;
              margin-left: auto;

              @media (max-width: $width-md) {
                max-width: 100%;
              }
            }
          }
        }

        .inner-box-right {
          //transform: translateX(670px) translateY(-200px);
          right: -670px;
          top: -200px;

          @media (max-width: 1300px) {
            right: -600px;
          }

          @media (max-width: $width-xl) {
            top: 0;
            margin-top: 50px;
            right: -200px;
          }

          @media (max-width: 800px) {
            right: -60px;
          }

          @media (max-width: $width-md) {
            right: 0px;
          }

          .txt-box {
          }

          .btn-box-collapse {
            .btn-inner {
              padding-left: 10px;

              @media (max-width: $width-md) {
                padding-left: 0;
              }
            }
          }
        }
      }

      .collapse {
        margin-top: 40px;

        .col-12 {
          .inner-collapse {
            border: 10px solid $blue-60per;
            padding: 30px;
          }
        }

        h4 {
          font-family: 'Helvetica Neue LT W05_75 Bold';
          margin-bottom: 20px;
        }

        ul {
          margin-bottom: 0;

          li {
            padding: 5px;
          }
        }
      }
    }
  }
}
</style>

<template>
  <SocialMediaIconBox />
  <div class="management-all">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="headline-box-std">
            <div class="headline-inner">
              <div class="breadcrumb">
                <span>{{ $t('General.ueber-uns') }}</span>
              </div>
              <h1>Management</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-grey">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <ul class="nav" id="vorstaende-nav" role="tablist">
              <li class="nav-item">
                <button
                  class="nav-link active"
                  id="markus-maier-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#markus-maier"
                  type="button"
                  role="tab"
                  aria-controls="markus-maier"
                  aria-selected="true"
                >
                  <span class="name"
                    >Markus Mair<span class="sub-title">{{ $t('management.vorstandsvorsitzender') }}</span></span
                  >
                </button>
              </li>
              <li class="nav-item">
                <button
                  class="nav-link"
                  id="bernhard-kiener-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#bernhard-kiener"
                  type="button"
                  role="tab"
                  aria-controls="bernhard-kiener"
                  aria-selected="false"
                >
                  <span class="name"
                    >Bernhard Kiener <span class="sub-title">{{ $t('management.vorstand') }}</span></span
                  >
                </button>
              </li>
              <li class="nav-item">
                <button
                  class="nav-link "
                  id="herwig-langanger-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#herwig-langanger"
                  type="button"
                  role="tab"
                  aria-controls="herwig-langanger"
                  aria-selected="false"
                >
                  <span class="name"
                    >Herwig Langanger<span class="sub-title">{{ $t('management.vorstand') }}</span></span
                  >
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="tab-content">
        <div class="tab-pane fade show active" id="markus-maier" role="tabpanel" aria-labelledby="markus-maier-tab">
          <div class="row justify-content-center">
            <div class="col-xl-4 col-lg-6 col-10">
              <img src="@/assets/img/ueber-uns/markus-mair-v5.jpg" alt="Markus Maier" class="img-fluid" />
            </div>
            <div class="col-xl-6 col-lg-6 offset-xl-1 col-10 align-self-center">
              <div class="txt-box">
                <div class="line"></div>
                <div class="zitat-box">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon zitat-icon-top" />
                  <h2 v-html="$t('management.zitat-maier')"></h2>
                </div>
                <p v-html="$t('management.mair-text-v1')"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade " id="herwig-langanger" role="tabpanel" aria-labelledby="herwig-langanger-tab">
          <div class="row justify-content-center">
            <div class="col-xl-4 col-lg-6 col-10">
              <img src="@/assets/img/ueber-uns/herwig-langanger-v2.jpg" alt="Herwig Langanger" class="img-fluid" />
            </div>
            <div class="col-xl-6 col-lg-6 offset-xl-1 col-10 align-self-center">
              <div class="txt-box">
                <div class="line"></div>
                <div class="zitat-box">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon zitat-icon-top" />
                  <h2 v-html="$t('management.zitat-langanger')"></h2>
                </div>

                <p v-html="$t('management.langanger-text-v1')"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="bernhard-kiener" role="tabpanel" aria-labelledby="bernhard-kiener-tab">
          <div class="row justify-content-center">
            <div class="col-xl-4 col-lg-6 col-10">
              <img src="@/assets/img/ueber-uns/bernhard-kiener-v5.jpg" alt="Bernhard Kiener" class="img-fluid" />
            </div>
            <div class="col-xl-6 col-lg-6 col-10 offset-xl-1 align-self-center">
              <div class="txt-box">
                <div class="line"></div>
                <div class="zitat-box">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon zitat-icon-top" />
                  <h2 v-html="$t('management.zitat-kiener')"></h2>
                </div>

                <p v-html="$t('management.kiener-text-v1')"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="border-boxen" id="aufsichtsratboxen">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="inner-box-border inner-box-left" v-inview="['animation-delay-0-5s', 'move-in-from-left']">
              <div class="txt-box">
                <h3>{{ $t('management.title-aufsichtsrat') }}</h3>
                <p>{{ $t('management.text-aufsichtsrat') }}</p>
              </div>
              <div class="btn-box-collapse">
                <a
                  href="#collapseAufsichtsrat"
                  v-smooth-scroll="{ offset: -100 }"
                  class="btn-inner"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseAufsichtsrat"
                  aria-expanded="false"
                  aria-controls="collapseAufsichtsrat"
                  >{{ $t('management.btn-aufsichtsrat') }}</a
                >
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="inner-box-border inner-box-right" v-inview="['animation-delay-0-5s', 'move-in-from-right']">
              <div class="txt-box">
                <h3>{{ $t('management.title-eigentuemer') }}</h3>
                <p>{{ $t('management.text-eigentuemer') }}</p>
              </div>
              <div class="btn-box-collapse">
                <a
                  href="#collapseEigentuemer"
                  v-smooth-scroll="{ offset: -200 }"
                  class="btn-inner"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEigentuemer"
                  aria-expanded="false"
                  aria-controls="collapseEigentuemer"
                  >{{ $t('management.btn-eigentuemer') }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="collapse" id="collapseAufsichtsrat" data-bs-parent="#aufsichtsratboxen">
          <div class="row justify-content-center">
            <div class="col-lg-6 col-12">
              <div class="inner-collapse">
                <h4>{{ $t('management.mitglieder-sind') }}</h4>
                <ul>
                  <li>GD Dr. Othmar Ederer {{ $t('management.vorsitz-aufsichtsrat') }}</li>
                  <li>Univ.-Prof. Mag. Dr. Johannes Zollner {{ $t('management.stv-vorsitz-aufsichtsrat') }}</li>
                  <li>Mag.a Eva Heigl</li>
                  <li>Mag.a Petra Schachner-Kröll</li>
                  <li>Ute Groß {{ $t('management.betriebsrat-bestellt') }}</li>
                  <li>Michael Lohmeyer {{ $t('management.betriebsrat-bestellt') }}</li>
                  <li>Ing. Josef Klapsch {{ $t('management.betriebsrat-bestellt') }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="collapse" id="collapseEigentuemer" data-bs-parent="#aufsichtsratboxen">
          <div class="row justify-content-center">
            <div class="col-lg-6 col-12">
              <div class="inner-collapse">
                <h4>{{ $t('management.wir-haben-geschichte') }}</h4>
                <p class="margin-b-s">{{ $t('management.eigentuemer-txt-v2') }}</p>

                <h4>{{ $t('management.wir-bekennen-uns') }}</h4>
                <p class="margin-b-s">{{ $t('management.eigentuemer-txt-v3') }}</p>

                <h4>{{ $t('management.eigentuemer-vorstand-txt') }}</h4>
                <ul>
                  <li>GD Dr. Othmar Ederer {{ $t('management.vorsitz') }}</li>
                  <li>Dr. Friedrich Santner {{ $t('management.stv-vorsitz') }}</li>
                  <li>Mag.a Sieglinde Pailer</li>
                  <li>Mag.a Eva Heigl</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import { mdiChevronUp, mdiFormatQuoteClose } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Management | Styria Media Group',
      keywords: ['management', 'vorstand', 'markus mair', 'kurt kribitz', 'bernhard kiener', 'aufsichtsrat', 'eigentümer', 'styria'],
      description: 'Markus Mair, Kurt Kribitz, Bernhard Kiener: die Vorstände der Styria Media Group.',
      image: require('@/assets/img/og/ueber-uns.png'),
    },
    en: {
      title: 'Management | Styria Media Group',
      keywords: ['management', 'vorstand', 'markus mair', 'kurt kribitz', 'bernhard kiener', 'aufsichtsrat', 'eigentümer', 'styria'],
      description: 'Markus Mair, Kurt Kribitz, Bernhard Kiener: the executive board of the Styria Media Group.',
      image: require('@/assets/img/og/ueber-uns.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    Footer,
  },
  setup() {
    return {
      mdiChevronUp,
      mdiFormatQuoteClose,
    };
  },
});
</script>
